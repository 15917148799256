import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Image from './Image';
import { decodeEntities } from '../utils/htmlParse';
import FormModalButton from './FormModalButton';
import './KnowledgeHubRecents.scss';
import './PostListItem.scss';

const KnowledgeHubRecents = (props) => {
  const { data } = props;
  const {
    recentPosts,
    recentBrochures,
    recentWhitepapers,
    recentVideos,
    wordpressAcfOptions: {
      options: {
        popularTopics
      }
    },
    site,
  } = data;
  const { blogSlug, publicationsSlug, siteUrl } = site.siteMetadata;
  return (
    <section className="knowledge-hub-recents">
      <div className="wrap">
        <div className="recent-articles">
          <h2 className="title">Latest Ideas & Insights</h2>
          {recentPosts && recentPosts.edges && (
            <div className="posts">
              {recentPosts.edges.map(({node: post}) => {
                return(
                  <div className="post">
                    <div className="meta">
                      {/* eslint-disable-next-line */}
                      <p>{post.categories && `${post.categories[0].name} / `} {post.date}</p>
                      {/* eslint-disable-next-line */}
                      {post.author && <p className="author">By {post.author.name}</p>}
                    </div>
                    <div className="container">
                      <Link className="featured-image" to={`/${blogSlug}/${post.slug}/`} aria-label={post.title}>
                        <Image image={post.featuredImage} />
                      </Link>
                      <h2 className="title"><Link className="animated-underline" to={`/${blogSlug}/${post.slug}/`} aria-label={post.title}>{decodeEntities(post.title)}</Link></h2>
                      <div className="excerpt" dangerouslySetInnerHTML={{__html: post.excerpt}} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="recent-publications">
          {popularTopics && (
            <div className="publications popular-topics">
              <h3>Popular Topics</h3>
              {popularTopics.map((topic) => {
                return <Link to={`/search?query=${topic.topic}&origin=hub`}>{topic.topic}</Link>;
              })}
            </div>
          )}
          {recentVideos && recentVideos.edges && (
            <div className="publications">
              <h3>Videos</h3>
              {recentVideos.edges.map(({node: video}) => {
                return (
                  <div className="publication video">
                    <div className="meta">
                      {/* eslint-disable-next-line */}
                      <p>Video / {video.date}</p>
                      {/* eslint-disable-next-line */}
                      {video.author && <p className="author">By {video.author.name}</p>}
                    </div>
                    <Link className="title" to={`/${publicationsSlug}/${video.slug}/`} aria-label={video.title}>
                      {decodeEntities(video.title)}
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
          {recentBrochures && recentBrochures.edges && (
            <div className="publications">
              <h3>Brochures</h3>
              {recentBrochures.edges.map(({node: brochure}) => {
                return (
                  <div className="publication brochure">
                    <div className="meta">
                      <p>Brochures</p>
                    </div>
                    <FormModalButton
                      className="title"
                      siteUrl={siteUrl}
                      file={brochure.acf.file.source_url}
                      text={brochure.title}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {recentWhitepapers && recentWhitepapers.edges && (
            <div className="publications">
              <h3>Whitepapers</h3>
              {recentWhitepapers.edges.map(({node: whitepaper}) => {
                return (
                  <div className="publication whitepaper">
                    <div className="meta">
                      <p>Whitepapers</p>
                    </div>
                    <FormModalButton
                      className="title"
                      siteUrl={siteUrl}
                      file={whitepaper.acf.file.source_url}
                      text={whitepaper.title}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            blogSlug
            publicationsSlug
            siteUrl
          }
        }
        # Posts
        recentPosts: allWordpressPost(
          limit: 6
          filter: { slug: { ne: "dummy-post" } }
        ) {
          edges {
            node {
              categories {
                name
              }
              featuredImage: featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              date(formatString: "DD.MM.YYYY")
              author {
                name
              }
              slug
              title
              excerpt
            }
          }
        }
      	# Brochures
      	recentBrochures: allWordpressWpResource(filter: {resource_type: {eq: 18}}, limit: 3) {
          edges {
            node {
              slug
              title
              acf {
                file {
                  source_url
                }
              }
            }
          }
        }
        # Whitepapers
        recentWhitepapers: allWordpressWpResource(filter: {resource_type: {eq: 17}}, limit: 3) {
          edges {
            node {
              slug
              title
              acf {
                file {
                  source_url
                }
              }
            }
          }
        }
        # Videos
        recentVideos: allWordpressWpResource(filter: {resource_type: {eq: 19}}, limit: 3) {
          edges {
            node {
              slug
              title
              date(formatString: "DD.MM.YYYY")
              author {
                name
              }
            }
          }
        }
        # Popular Topics
        wordpressAcfOptions(options: { }) {
          options {
            popularTopics {
              topic
            }
          }
        }
      }
    `}
    render={data => <KnowledgeHubRecents data={data} {...props} />}
  />
)
