import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import PostBlock from '../components/PostBlock';
import { decodeEntities } from '../utils/htmlParse';
import { KnowledgeHubHeader } from '../components/KnowledgeHubHeader';
import KnowledgeHubRecents from '../components/KnowledgeHubRecents';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import './page.scss';
import '../acf/ThreeColumnLinksList.scss';

const AcfComponent = ({ item, location, wordpressUrl, siteUrl, parent, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      parent={parent}
      location={location}
      wordpressUrl={wordpressUrl}
      siteUrl={siteUrl}
      {...item}
    />
  );
};

export const PageTemplate = ({ title, content }) => {
  return (
    <section className="default-content">
      <div className="wrap">
        <h2 className="title" dangerouslySetInnerHTML={{__html: title}} />
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </section>
  )
}

const Page = (props) => {
  const { data, location } = props;
  const { wordpressPage: page, site, allWordpressPost } = data;
  if (!page) return null;
  const { edges: posts } = allWordpressPost;
  const { title, content, parent, yoast, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle, wordpressUrl, blogSlug, siteUrl } = site.siteMetadata;
  const isHome = !location.pathname || location.pathname === '/';
  const isKnowledgeHub = (location.pathname === '/knowledge-hub') || (location.pathname === '/knowledge-hub/');
  return (
    <Layout location={location}>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
      />
      {isKnowledgeHub && (
        <>
          <KnowledgeHubHeader location={location} />
          <KnowledgeHubRecents />
        </>
      )}
      {layout ? layout.map(item => {
        const layoutComponentName = item.name.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={item.id}
            parent={parent}
            componentName={layoutComponentName}
            item={item}
            location={location}
            wordpressUrl={wordpressUrl}
            siteUrl={siteUrl}
          />
        );
      }) : (
        <PageTemplate
          title={title}
          content={content}
        />
      )}
      {isHome && (
        <PostBlock
          posts={posts}
          title="Ideas & Insights"
          buttonText="More Ideas & Insights"
          buttonUrl={`/${blogSlug}`}
          isSlider
        />
      )}
    </Layout>
  )
}

export default Previewable(Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
        siteUrl
        functionsUrl
      }
    }
    wordpressPage(id: { glob: $id }) {
      title
      content,
      parent: parent_element {
        slug,
        title
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          name: __typename,
     			... on WordPressAcf_HomeSlider {
            id,
            slides {
              title,
              subtitle,
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              },
              hasButton,
              buttonLink,
              buttonLabel
            }
          },
          ... on WordPressAcf_GridBlock {
            id,
            photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            },
            title,
            headline,
            bodyText,
            orientation,
            theme,
            hasButtons,
            buttons {
              buttonLabel,
              buttonLink,
            }
          },
          ... on WordPressAcf_GridSliderBlock {
            id,
            slides {
              photo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              },
              title,
              headline,
              bodyText,
              hasButtons,
              buttons {
                buttonLabel,
                buttonLink,
              }
            }
          },
          ... on WordPressAcf_CallToActionPair {
            id,
            callToActions {
              graphic {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              },
              theme,
              graphicPosition,
              title,
              headline,
              bodyText,
              hasButton,
              buttonLink,
              buttonLabel,
            }
          },
          ... on WordPressAcf_CallToActionGroup {
            id,
            background,
            callToActions {
              theme,
              title,
              headline,
              bodyText,
              hasButton,
              buttonLink,
              buttonLabel,
            }
          },
          ... on WordPressAcf_PageHeader {
            id
            type
            title
            subtitle
            headline
            bodyText
            graphic {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            videoUrl
            quote
            hasButton
            buttonLabel
            buttonLink
          },
          ... on WordPressAcf_CallToAction {
            id
            theme
            type
            title
            headline
            bodyText
            hasButton
            buttonLabel
            buttonLink
            buttonDownload {
              link: source_url
            }
            externalButtonLink
            quoteeTitle
            quoteeName
          },
          ... on WordPressAcf_DiagramBlock {
            id
            hasTitle
            title
            hasLogo
            logo {
              url {
                source_url
              }
            }
            steps {
              stepText
            }
            hasOutcome
            outcome
            outcomeSubtitle
            headline
            bodyTextColumns {
              bodyTextColumn
            }
          },
          ... on WordPressAcf_FormBlock {
            id
            title
            headline
            subtitle
            form
          },
          ... on WordPressAcf_MapBlock {
            id
            map {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            headline
            bodyText
          },
          ... on WordPressAcf_PageHeaderShort {
            id
            title
            subtitle
            increasedPadding
          },
          ... on WordPressAcf_PageNavigationBlock {
            id
  					leftLink {
              link
              linkLabel
              linkHeadline
              linkSubtitle
            }
            rightLink {
              link
              linkLabel
              linkHeadline
              linkSubtitle
            }
          },
          ... on WordPressAcf_TextBlock {
            id
            title
            titleIsPageHeading
            format
            columnOneText
            columnTwoText
            hasButton
            buttonType
            buttonLabel
            file {
              source_url
            }
            internalLink
            externalLink
            hash
          }
          ... on WordPressAcf_RelatedPostsBlock {
            id
            title
            theme
            posts {
              wordpress_id
              title
              excerpt
              date
              slug
              author {
                name
              }
              featuredImage: featured_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              categories {
                name
                slug
              }
            }
          }
          ... on WordPressAcf_ListBlock {
            id
            hasLogo
            logo {
              logoUrl: source_url
            }
            title
            listItems {
              text
            }
          }
          ... on WordPressAcf_LinksListBlock {
            id
            title
            subtitle
            links {
              linkType
              label
              title
              url
              pageLink
            }
          }
          ... on WordPressAcf_QuizBlock {
            id
            quiz
            hasIntroPage
            quizIntro
            startQuizButtonLabel
            quizResults {
              lowerBoundary
              upperBoundary
              resultAsset {
                link: source_url
              }
            }
          }
          ... on WordPressAcf_ThreeColumnLinksList {
            linkGroups {
              title
              links {
                title
                pdfUrl: url {
                  url: source_url
                }
                externalUrl
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "dummy-post" } }
      limit: 6
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
